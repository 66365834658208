class Accordion {
  constructor(selector) {
    this.button = selector;
    this.init();
  }

  init() {
    this.button.addEventListener('click', (e) => {
      if (e.target.classList.contains('js-collapse-all')) {
        // e.preventDefault();
        const wrapper = this.button.closest('.js-accordion__item');
        wrapper
          .querySelectorAll("[aria-expanded='true']")
          .forEach((element) => element.setAttribute('aria-expanded', 'false'));
      } else if (e.target.classList.contains('js-faq-anchor')) {
        document
          .querySelector(e.target.hash)
          .setAttribute('aria-expanded', true);
      } else if (!e.target.classList.contains('c-btn')) {
        e.preventDefault();
        this.currentAriaExpanded = this.button.getAttribute('aria-expanded');
        this.newAriaExpanded =
          this.currentAriaExpanded === 'true' ? 'false' : 'true';
        this.button.setAttribute('aria-expanded', this.newAriaExpanded);
      }
    });
  }
}

export default Accordion;
